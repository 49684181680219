'use client';
import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import FetchUtils from '../Utils/FetchUtils';
import useAbortRegistry from '../Hooks/useAbortRegistry';
import NotificationUtils from '../Utils/NotificationUtils';

export type RuntimeConfigValue<T> = {
	isLoading: boolean;
	config: T | null;
};

export const RuntimeConfig = createContext<RuntimeConfigValue<unknown> | null>(null);

export type RuntimeConfigProviderProps = {
	children?: React.ReactNode;
	runtimeEndpoint?: string;
};

export default function RuntimeConfigProvider<T>(props: RuntimeConfigProviderProps) {
	const { children } = props;

	const registerAbort = useAbortRegistry();

	const runtimeEndpoint = props.runtimeEndpoint ?? '/config/config.json';

	const [runtimeConfigValue, setRuntimeConfigValue] = useState<RuntimeConfigValue<T>>({
		config: null,
		isLoading: true,
	});

	useEffect(() => {
		const request = FetchUtils.getJson<T>(runtimeEndpoint);
		FetchUtils.abortableRequest(request, registerAbort)
			.response.then((config) => {
				setRuntimeConfigValue({
					config,
					isLoading: false,
				});
			})
			.catch((err) => NotificationUtils.showError(err as Error, 'Failed to fetch runtime configuration'));
	}, [registerAbort, runtimeEndpoint]);

	return <RuntimeConfig.Provider value={runtimeConfigValue}>{children}</RuntimeConfig.Provider>;
}

export function useRuntimeConfigProvider<T>() {
	const context = React.useContext(RuntimeConfig) as RuntimeConfigValue<T> | null;
	if (!context) {
		throw new Error('useRuntimeConfigProvider must be used within an RuntimeConfigProvider');
	}
	return context;
}

export function useOptionalRuntimeConfigProvider<T>() {
	const context = React.useContext(RuntimeConfig) as RuntimeConfigValue<T> | null;
	if (!context) {
		return null;
	}
	return context;
}
