'use client';

import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { createContext } from 'react';
import { Stripe, StripeConstructorOptions, StripeElements, StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { useRuntimeConfigProvider } from '../../Config/RuntimeConfigProvider';
import { merge } from 'lodash-es';

export type StripeElementsRef = {
	stripe: Stripe | null;
	elements: StripeElements | null;
};

export type StripeContextValue = {
	promise: Promise<Stripe | null>;
	setPromise: Dispatch<SetStateAction<Promise<Stripe | null>>>;
};

export const StripeContext = createContext<StripeContextValue | null>(null);

export type StripeProviderProps = {
	children?: React.ReactNode;
	options?: StripeConstructorOptions;
};

export type StripeRuntimeConfig = {
	stripePublicKey?: string;
};

export default function StripeProvider(props: StripeProviderProps) {
	const { children, options } = props;

	const { isLoading, config } = useRuntimeConfigProvider<StripeRuntimeConfig>();

	if (!isLoading && !config?.stripePublicKey) {
		throw new Error('Stripe public key not set (or runtime config not loaded)');
	}

	const [promise, setPromise] = React.useState<Promise<Stripe | null>>(Promise.resolve(null));

	useEffect(() => {
		if (!isLoading && config?.stripePublicKey) {
			setPromise(loadStripe(config?.stripePublicKey, options));
		}
	}, [config?.stripePublicKey, isLoading, options]);

	const stripeContextValue: StripeContextValue = {
		promise,
		setPromise,
	};

	return <StripeContext.Provider value={stripeContextValue}>{children}</StripeContext.Provider>;
}

export function useStripeProvider() {
	const context = React.useContext(StripeContext);
	if (!context) {
		throw new Error('useStripeProvider must be used within an StripeProvider');
	}
	return context;
}

export function createStripeElementsOptions(
	color: string,
	clientSecret: string | null = null,
	overrideOptions: StripeElementsOptions = {},
) {
	const defaultOptions: StripeElementsOptions = {
		fonts: [
			{
				cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap',
			},
		],
		loader: 'always',
		appearance: {
			theme: 'flat',
			variables: {
				borderRadius: '0px',
				colorBackground: 'white',
				fontFamily: '"Montserrat", sans-serif',
				colorText: color,
				colorPrimary: color,
				fontSmooth: 'always',
			},
			labels: 'above',
			disableAnimations: true,
			rules: {
				'.Input': {
					borderBottom: `2px solid ${color}`,
				},
			},
		},
	};
	defaultOptions.loader = 'always';
	if (clientSecret) {
		defaultOptions.clientSecret = clientSecret;
	}

	const mergedOptions = merge(defaultOptions, overrideOptions);

	return mergedOptions;
}
